@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  position: relative;
  font-size: 100%;
  background-color: #eee;
  margin: 0;
}
body,
body * {
  font-family: "Roboto", Tahoma, Arial, sans-serif;
  box-sizing: border-box;
}
main {
  max-width: 100%;
  width: 680px;
  margin: 0 auto;
  padding: 1rem;
  background-color: white;
}
hr {
  display: block;
  margin-bottom: 1rem;
  margin-top: 3rem;
}
@media screen and (max-width: 640px) {
  hr {
    margin: 1rem 0;
  }
}
h1 {
  font-family: "Alex Brush", "Roboto", Tahoma, Arial, sans-serif;
  font-size: 4rem;
  letter-spacing: 0rem;
}
@media screen and (max-width: 640px) {
  h1 {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }
}
h2 {
  font-size: 1.8rem;
  margin-top: 3rem;
}
h3 {
  font-size: 1.6rem;
  margin-top: 2rem;
}
p,
li {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.6rem;
}
ul {
  list-style: square outside;
}
code {
  padding: 0.2em 0.4em;
  border-radius: 3px;
  background-color: #ddd;
  color: black;
}
table {
  margin-top: 1rem;
}
table td {
  padding: 0.3rem;
}
table tr:not(:last-child) td:nth-child(2), table tr:not(:last-child) td:nth-child(3) {
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}
header {
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: #666;
  color: white;
  border-bottom: 4px solid #aaa;
}

footer {
  padding-bottom: 1rem;
}
.prism-code {
  font-size: 1rem;
  line-height: 1.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  letter-spacing: 0.05em;
  -webkit-overflow-scrolling: touch;
  background-color: transparent;
  overflow: scroll;
  margin-top: 1rem;
}

.token {
  display: inline-block;
}

.line-number-style {
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
  width: 1.2em;
  user-select: none;
  opacity: 0.3;
  text-align: center;
  position: relative;
}

.highlight-line {
  background-color: #023751;
  border-left: 4px solid #029bce;
}

.highlight-line .line-number-style {
  opacity: 0.5;
  width: calc(1.2em - 4px);
  left: -2px;
}
nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
nav a {
  color: white;
  margin: 0 2rem;
  font-weight: 500;
  transition: all 500ms ease;
}
nav a:hover {
  color: aqua;
}
/*# sourceMappingURL=index.cf3f66fb.css.map */
