header {
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: #666;
  color: white;
  border-bottom: 4px solid #aaa;
}

footer {
  padding-bottom: 1rem;
}
