table {
  margin-top: 1rem;

  td {
    padding: 0.3rem;
  }
  tr:not(:last-child) {
    td {
      &:nth-child(2),
      &:nth-child(3) {
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        border-top: 1px solid #f3f3f3;
      }
    }
  }
}
