@import "reset.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");

html,
body {
  position: relative;
  font-size: 100%;
  background-color: #eee;
  margin: 0;
}

body,
body * {
  font-family: "Roboto", Tahoma, Arial, sans-serif;
  box-sizing: border-box;
}

main {
  max-width: 100%;
  width: 680px;
  margin: 0 auto;
  padding: 1rem;
  background-color: white;
}

hr {
  display: block;
  margin-bottom: 1rem;
  margin-top: 3rem;

  @media screen and (max-width: 640px) {
    margin: 1rem 0;
  }
}

h1 {
  font-family: "Alex Brush", "Roboto", Tahoma, Arial, sans-serif;
  font-size: 4rem;
  letter-spacing: 0rem;

  @media screen and (max-width: 640px) {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }
}

h2 {
  font-size: 1.8rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.6rem;
  margin-top: 2rem;
}

p,
li {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.6rem;
}

ul {
  list-style: square outside;
}

code {
  padding: 0.2em 0.4em;
  border-radius: 3px;
  background-color: #ddd;
  color: black;
}
